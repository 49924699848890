import Layout from '@src/shared/Layout/Layout';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import { Box, Stack, TickIcon, Typography } from 'design-system';
import React, { useMemo } from 'react';
import { useOrderDetails } from '@src/shared/hooks';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';

export const PaymentSuccessPage: React.FC = () => {
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();

  const tripLocationLabel = useMemo(() => {
    return orderDetailsData?.orderDetails.location
      ? `to ${orderDetailsData.orderDetails.location}`
      : '';
  }, [orderDetailsData]);

  return (
    <Layout
      HeroComponent={
        <BookDepartureHeader title={'Your trip is booked!'} textAlign="center" />
      }
    >
      <Stack gap={4}>
        <Stack alignItems={'center'} gap={2}>
          <TickIcon
            sx={{
              width: {
                xs: '70px',
                sm: '98px',
              },
              height: {
                xs: '70px',
                sm: '98px',
              },
            }}
          />
          <Stack gap={1}>
            <Typography
              data-testid="payment-complete-message"
              textAlign="center"
              variant="H4"
              typography={{
                xs: 'H5',
                sm: 'H4',
              }}
            >
              Payment complete
            </Typography>
            <Typography
              textAlign="center"
              variant="Body M"
              typography={{
                xs: 'Body S',
                sm: 'Body M',
              }}
              maxWidth={729}
            >
              Your Adventure {tripLocationLabel} is booked. Your confirmation email is on
              its way with the exciting next steps.
            </Typography>
          </Stack>
        </Stack>
        <Box maxWidth={456} alignSelf={'center'}>
          <OrderDetails
            orderDetails={orderDetailsData?.orderDetails}
            loading={orderDetailsLoading}
          />
        </Box>
      </Stack>
    </Layout>
  );
};
