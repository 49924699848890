import React, { useMemo } from 'react';
import { FC } from 'react';
import {
  useTheme,
  Typography,
  GenericError,
  Box,
  Stack,
  Skeleton,
  Divider,
} from 'design-system';

import {
  Basket,
  CustomerCurrency,
  OrderDetails as OrderDetailsType,
  ProductLineSlug,
  TripDepartureStatus,
} from '@flashpack/graphql';
import { formatDateToOrdinalMonthYear } from '@src/shared/dateUtils';
import TripStatus from './TripStatus';
import { OrderCardImage } from './OrderCardImage';
import { PaymentBreakdownV3 } from './PaymentBreakdownV3';
import { TripDisclaimer } from './TripDisclaimer';
import { SxProps } from '@mui/material';
import { PaymentBreakdownContent } from '@src/v3/order-details-v3/PaymentBreakdownContent';

type OrderDetailsPropType = Pick<
  OrderDetailsType,
  | 'tripName'
  | 'location'
  | 'startDate'
  | 'endDate'
  | 'duration'
  | 'description'
  | 'tripStatus'
  | 'tripPrice'
  | 'promoCodesEnabled'
  | 'tripNote'
  | 'tourNote'
  | 'productLine'
  | 'insiderDiscount'
  | 'promotionalDiscount'
  | 'tripTotal'
>;
export type OrderDetailsPropTypes = {
  orderDetails?: OrderDetailsPropType;
  loadingOrderDetails: boolean;
  currencyCode: CustomerCurrency;
  basket?: Basket | null;
  loadingBasket: boolean;
  showTripImage?: boolean;
  sx?: SxProps;
};

export const OrderDetailsV3: React.FC<OrderDetailsPropTypes> = (props) => {
  const {
    orderDetails,
    loadingOrderDetails,
    showTripImage = true,
    basket,
    currencyCode,
    sx,
  } = props;
  const theme = useTheme();
  const dateLabels = useMemo(() => {
    if (!orderDetails || !orderDetails.startDate || !orderDetails.endDate) {
      return { startDate: '', endDate: '' };
    }
    const { startDate, endDate } = orderDetails;
    const start = formatDateToOrdinalMonthYear(startDate);
    const end = formatDateToOrdinalMonthYear(endDate);
    return {
      startDate: start,
      endDate: end,
    };
  }, [orderDetails]);

  if (loadingOrderDetails) {
    return <Skeleton variant="rectangular" height={547} sx={{ borderRadius: 6 }} />;
  }

  if (!orderDetails) {
    return (
      <GenericError
        error={
          'Something went wrong while fetching your order details. Please try again later.'
        }
      />
    );
  }

  const { tripName, duration, tripNote } = orderDetails;

  return (
    <Box
      sx={{ ...sx, backgroundColor: theme.palette.principal.grey30, borderRadius: '8px' }}
    >
      {showTripImage && <OrderCardImage />}
      <Stack gap={1} mb={2}>
        <Typography variant="H4">
          {tripName}
          {orderDetails.productLine === ProductLineSlug.FlashPack && ': Ages 30-49'}
        </Typography>
        <Typography variant="Body M bold">
          {dateLabels.startDate} – {dateLabels.endDate}
        </Typography>
        <Typography variant="Body M">{duration}</Typography>
      </Stack>
      <TripDisclaimer tripNote={tripNote} />
      <TripStatus status={orderDetails.tripStatus as TripDepartureStatus} />

      <Divider />
      <TripPriceList
        orderDetails={orderDetails}
        basket={basket}
        currencyCode={currencyCode}
      />
    </Box>
  );
};

const TripPriceList: FC<{
  orderDetails: OrderDetailsPropType;
  basket?: Basket | null;
  currencyCode: CustomerCurrency;
}> = ({ orderDetails, basket, currencyCode }) => {
  if (!orderDetails) {
    return null;
  }

  if (!basket) {
    const promotionalDiscountItem = orderDetails.promotionalDiscount
      ? {
          label: 'Promotional discount',
          price: -orderDetails.promotionalDiscount,
        }
      : null;

    const insiderDiscountItem = orderDetails.insiderDiscount
      ? {
          label: 'Insider discount',
          price: -orderDetails.insiderDiscount,
        }
      : null;

    const tripTotalItem = {
      label: 'Trip total',
      price: orderDetails.tripTotal,
    };

    const tripPriceItem = {
      label: 'Trip price',
      price: orderDetails.tripPrice,
    };

    return (
      <PaymentBreakdownContent
        currency={currencyCode}
        tripPrice={tripPriceItem}
        promotionalDiscount={promotionalDiscountItem}
        insiderDiscount={insiderDiscountItem}
        earlyBirdDiscount={null}
        tripTotal={tripTotalItem}
      />
    );
  }
  return <PaymentBreakdownV3 basket={basket} />;
};
