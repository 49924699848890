import { Config } from 'final-form';
import { useMutation } from '@apollo/client';
import { Field, Form } from 'react-final-form';
import {
  LoadingButton,
  FormTextInput,
  GenericError,
  Typography,
  Validator,
  createPrioritizedCountryOptions,
  composeValidators,
  ValidatorFactory,
  FormDropdown,
} from 'design-system';
import { useCallback, useMemo } from 'react';
import Layout from '@src/shared/Layout/Layout';
import { CustomerCurrency } from '@flashpack/graphql';
import { Box, Stack } from '@mui/system';
import BookDepartureHeader from '@src/shared/book-departure-header/BookDepartureHeader';
import StepperPill from '@src/shared/stepper-pill/StepperPill';
import ReservationTimer from '@src/book-departure/reservation-timer/ReservationTimer';
import { useBasket, useExpiredReservationRedirect, useRouting } from '@src/shared/hooks';
import { UpdateCustomerBillingDetailsDocument } from '@flashpack/graphql';
import { CheckoutRoutePath } from '@src/routing/checkoutRoutePath';
import { OrderDetails } from '@src/shared/order-details/OrderDetails';
import { useOrderDetails } from '@src/shared/hooks/useOrderDetails';
import { BookingTermsAndConditionsFormField } from '@src/shared/booking-terms-and-conditions/BookingTermsAndConditionsFormField';
import { PageFormTitle } from '@src/shared/page-form-title/PageFormTitle';
import { ResponsiveSideBySide } from '@src/shared/responsive-side-by-side/ResponsiveSideBySide';
import { PageFormLoader } from '@src/shared/page-form-loader/PageFormLoader';
import ToPayWidget from '@src/pay/ToPayWidget';

type BillingDetailsFormValues = {
  acceptedTermsAndConditions: boolean;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
};

export const BillingDetailsPage: React.FC = () => {
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { currencyCode } = queryParams;
  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;

  const { navigate } = useRouting();
  const { basket, loading, error } = useBasket();
  const { data: orderDetailsData, loading: orderDetailsLoading } = useOrderDetails();
  const [updateBillingDetails] = useMutation(UpdateCustomerBillingDetailsDocument);

  const prioritizedCountryOptions = useMemo(
    () =>
      createPrioritizedCountryOptions(['us', 'gb']).map((option) => ({
        label: option.label,
        value: option.value.toUpperCase(),
      })),
    [],
  );

  const termsAndConditions = basket?.terms?.content;

  useExpiredReservationRedirect({
    skip: loading || !basket?.reservation,
    reservationExpired: !!basket?.reservation?.expired,
    reservationExpiresIn: basket?.reservation?.expiresIn,
  });

  const continueToPayment: Config<BillingDetailsFormValues>['onSubmit'] = useCallback(
    (formValues) => {
      if (!basket) {
        return;
      }
      // return promise to capture submit loading state
      return (async () => {
        await updateBillingDetails({
          variables: {
            input: {
              address: {
                addressLine1: formValues.addressLine1,
                addressLine2: formValues.addressLine2,
                city: formValues.city,
                countryCode: formValues.country,
                postCode: formValues.postcode,
              },
            },
          },
        });
        navigate(CheckoutRoutePath.PAY.value, { preserveExisting: true });
      })();
    },
    [basket, navigate],
  );

  if (loading) {
    return (
      <Layout
        HeroComponent={
          <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
        }
      >
        <PageFormLoader />
      </Layout>
    );
  }

  if (error || !basket) {
    return (
      <GenericError
        error={'Something went wrong while creating your order. Please try again later.'}
      />
    );
  }

  return (
    <Layout
      HeroComponent={
        <BookDepartureHeader location={orderDetailsData?.orderDetails.location} />
      }
    >
      <ResponsiveSideBySide
        mainSection={
          <Stack gap={3}>
            <StepperPill currentStep={3} totalSteps={3} />
            <PageFormTitle>Details and terms</PageFormTitle>
            <Stack gap={1}>
              {basket.reservation && (
                <ReservationTimer endTime={new Date(basket.reservation.expiresAt)} />
              )}
            </Stack>
            <Typography variant="H5">Billing address</Typography>
            <Form
              onSubmit={continueToPayment}
              render={({ handleSubmit, submitting }) => {
                return (
                  <form
                    id="checkout-billing-address"
                    autoComplete="on"
                    onSubmit={(e) => {
                      void handleSubmit();
                      e?.preventDefault();
                    }}
                  >
                    <Stack gap={2}>
                      <FormTextInput
                        name="addressLine1"
                        validate={Validator.required}
                        textInputProps={{
                          autoComplete: 'address-line1',
                          placeholder: 'Enter address...',
                          label: 'Address line 1',
                          size: 'large',
                        }}
                      />
                      <FormTextInput
                        name="addressLine2"
                        textInputProps={{
                          autoComplete: 'address-line2',
                          placeholder: 'Enter address...',
                          label: 'Address line 2',
                          size: 'large',
                        }}
                      />
                      <FormTextInput
                        name="city"
                        validate={Validator.required}
                        textInputProps={{
                          autoComplete: 'address-level2',
                          placeholder: 'Enter town/city...',
                          label: 'Town/City',
                          size: 'large',
                        }}
                      />
                      <FormTextInput
                        name="postcode"
                        textInputProps={{
                          autoComplete: 'postal-code',
                          placeholder: 'Enter code...',
                          label: 'Postcode/Zipcode',
                        }}
                        validate={composeValidators(
                          Validator.required,
                          ValidatorFactory.createMaxLength(10),
                        )}
                      />
                      <FormDropdown
                        name="country"
                        options={prioritizedCountryOptions}
                        validate={Validator.required}
                        TextInputProps={{
                          size: 'large',
                          label: 'Country',
                          placeholder: 'Select country...',
                          testid: 'select-country',
                          inputProps: {
                            autoComplete: 'nope', // 'country',
                          },
                        }}
                      />

                      {termsAndConditions && (
                        <Box mt={1}>
                          <Field
                            name="acceptedTermsAndConditions"
                            type="checkbox"
                            validate={Validator.required}
                          >
                            {(props) => (
                              <BookingTermsAndConditionsFormField
                                termsAndConditionsHTML={termsAndConditions}
                                {...props}
                              />
                            )}
                          </Field>
                        </Box>
                      )}

                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        data-testid="billing-details-continue"
                        sx={{ marginTop: '16px', alignSelf: 'self-start' }}
                        loading={submitting}
                      >
                        Continue to payment
                      </LoadingButton>
                    </Stack>
                  </form>
                );
              }}
            />
          </Stack>
        }
        additionalSection={
          <Stack gap={3}>
            <OrderDetails
              loading={orderDetailsLoading || loading}
              orderDetails={orderDetailsData?.orderDetails}
              isFlashPerk={Boolean(basket.promotionalDiscount)}
            />

            <ToPayWidget currency={customerCurrency} basket={basket} />
          </Stack>
        }
      />
    </Layout>
  );
};
