import { useMutation } from '@apollo/client';
import { ApplyPromoCodesToBasketDocument, Promocode } from '@flashpack/graphql';
import {
  Button,
  Checklist,
  CircleCloseIcon,
  TextInput,
  LoadingButtonWithIcon,
  Stack,
  TextButton,
  useTheme,
  useMediaQuery,
} from 'design-system';
import React, { useCallback, useMemo, useState } from 'react';

interface PropTypes {
  appliedPromocodes?: null | Array<Omit<Promocode, '__typename'>>;
  basketId: number;
  departureCode: string;
  currencyCode: string;
  onChange?: () => void;
}

const PromotionalCode: React.FC<PropTypes> = (props) => {
  const { basketId, departureCode, currencyCode, onChange } = props;

  const [code, setCode] = useState(props.appliedPromocodes?.[0]?.code || '');
  const [applyPromoCodesToBasketMutation] = useMutation(ApplyPromoCodesToBasketDocument);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [promocodesState, setPromocodesState] = useState<{
    appliedPromocodes: string[];
    invalidPromocodes: string[];
    isLoading: boolean;
  }>({
    appliedPromocodes: props.appliedPromocodes?.map((p) => p.code) || [],
    invalidPromocodes: [],
    isLoading: false,
  });
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleApplyCode = useCallback(() => {
    if (!basketId) {
      return;
    }

    setPromocodesState((s) => ({
      ...s,
      isLoading: true,
    }));

    void (async () => {
      try {
        const response = await applyPromoCodesToBasketMutation({
          variables: {
            input: {
              basketId,
              promocodes: [code],
              departureCode,
              currencyCode,
            },
          },
        });

        onChange?.();

        if (!response.data || !response.data.applyPromoCodesToBasket.appliedPromocodes) {
          // no response or no promocodes
          setPromocodesState((s) => ({
            ...s,
            isLoading: false,
            invalidPromocodes: [...s.invalidPromocodes, code],
          }));
          return;
        }

        const appliedPromocodes =
          response.data.applyPromoCodesToBasket.appliedPromocodes.map((p) => p.code);

        const isCurrentPromocodeValid = appliedPromocodes.includes(code);

        setPromocodesState((s) => ({
          isLoading: false,
          appliedPromocodes: isCurrentPromocodeValid
            ? appliedPromocodes
            : s.appliedPromocodes,
          invalidPromocodes: isCurrentPromocodeValid
            ? s.invalidPromocodes.filter((p) => p !== code)
            : [...s.invalidPromocodes, code],
        }));
      } catch (error) {
        setPromocodesState((s) => ({
          ...s,
          isLoading: false,
          invalidPromocodes: [...s.invalidPromocodes, code],
        }));
      }
    })();
  }, [applyPromoCodesToBasketMutation, basketId, code, departureCode, currencyCode]);

  const handleRemoveCode = useCallback(() => {
    void (async () => {
      setPromocodesState((s) => ({
        ...s,
        isLoading: true,
      }));

      await applyPromoCodesToBasketMutation({
        variables: {
          input: {
            basketId,
            promocodes: [],
            departureCode,
            currencyCode,
          },
        },
      });

      onChange?.();

      setPromocodesState((s) => ({
        ...s,
        appliedPromocodes: s.appliedPromocodes.filter((p) => p !== code),
        isLoading: false,
      }));
    })();
  }, [code, basketId, departureCode, currencyCode, applyPromoCodesToBasketMutation]);

  const isAddCodeButtonDisabled = useMemo(() => {
    const isBasketEmpty = !basketId;
    const isCodeInvalid = !code;
    const isPromocodeLoading = promocodesState.isLoading;
    return isBasketEmpty || isCodeInvalid || isPromocodeLoading;
  }, [basketId, code, promocodesState.isLoading]);

  const isDefaultState = useMemo(() => {
    return !promocodesState.isLoading && promocodesState.appliedPromocodes.length === 0;
  }, [promocodesState]);

  const isLoading = promocodesState.isLoading;

  const isError = useMemo(() => {
    return Boolean(
      !promocodesState.isLoading &&
        promocodesState.invalidPromocodes.includes(code) &&
        code,
    );
  }, [promocodesState, code]);

  const isSuccess = useMemo(() => {
    return Boolean(
      !promocodesState.isLoading &&
        promocodesState.appliedPromocodes.includes(code) &&
        code,
    );
  }, [promocodesState, code]);

  const isTextFieldReadonly = useMemo(() => {
    return promocodesState.isLoading || promocodesState.appliedPromocodes.includes(code);
  }, [promocodesState, code]);

  return (
    <Stack direction={'row'} gap={1} alignItems={'start'}>
      <TextInput
        name="code"
        value={code}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleApplyCode();
          }
        }}
        onChange={handleCodeChange}
        error={isError}
        helperText={isError ? 'Code not valid' : ' '}
        disabled={isTextFieldReadonly}
        size="medium"
        InputProps={{
          startAdornment: isSuccess ? (
            <Checklist sx={{ color: 'system.green100', mr: 0.5 }} />
          ) : null,
        }}
        placeholder="Enter code..."
      />

      {isDefaultState && (
        <Button
          variant="outlined"
          onClick={handleApplyCode}
          disabled={isAddCodeButtonDisabled}
          size={isSmallScreen ? 'small' : 'medium'}
          sx={{ height: '45px', minWidth: '100px' }}
        >
          Add Code
        </Button>
      )}
      {isLoading && <LoadingButtonWithIcon />}
      {isSuccess && (
        <TextButton
          onClick={handleRemoveCode}
          size={isSmallScreen ? 'small' : 'medium'}
          startIcon={<CircleCloseIcon sx={{ color: 'system.red100' }} />}
          sx={{
            color: 'system.red100',
            ':hover': { backgroundColor: 'system.red10' },
          }}
        >
          {isSmallScreen ? 'Remove' : 'Remove code'}
        </TextButton>
      )}
    </Stack>
  );
};

export default PromotionalCode;
