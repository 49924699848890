import { useOrderDetails, useRouting } from '@src/shared/hooks';
import { CheckoutExpandableSection } from '@src/shared/checkout-sections-expandable/CheckoutExpandableSections';
import { CheckoutSectionName } from '@src/v3/checkout-sections/CheckoutSections';
import {
  GenericError,
  LoadingButton,
  RadioAccordionGroup,
  RadioAccordionGroupOption,
  Stack,
  Typography,
  useTheme,
} from 'design-system';
import {
  Basket,
  CustomerCurrency,
  PaymentOptionType,
  UpdateBasketPaymentPlanDocument,
} from '@flashpack/graphql';
import { useMemo, useState } from 'react';
import {
  getDefaultPaymentOptionType,
  isOnlyFullBalanceOptionAvailable,
} from '@src/book-departure/payment-options/paymentOptionsUtil';

import { getPaymentPlanLatestStartDate } from '@src/shared/instalments-calculator/InstalmentsCalculator';
import { formatDateToOrdinalMonthYear } from '@src/shared/dateUtils';
import { subDays } from 'date-fns';
import { formatCurrency } from '@src/shared/utils/currencyUtils';
import { createPaymentPlanOptionContentV2 } from '@src/book-departure/payment-options/PaymentPlanOptionV2';
import { useMutation } from '@apollo/client';
import PromotionalCode from '@src/book-departure/promotional-code/PromotionalCode';
import { Box } from '@mui/material';

export const PaymentOptionsSection = ({
  completed,
  basket,
  onComplete,
  activeSection,
  editable,
}: {
  activeSection: CheckoutSectionName;
  basket: Basket;
  completed: boolean;
  onComplete: (sectionName: CheckoutSectionName) => void;
  editable?: boolean;
}) => {
  const { queryParams } = useRouting<{
    departureCode: string;
    currencyCode: string;
  }>();
  const { departureCode, currencyCode } = queryParams;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const [updateBasketPaymentPlan] = useMutation(UpdateBasketPaymentPlanDocument);
  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();
  const { availablePaymentOptions, tourDeposit, departureDate } = basket;
  const { data: orderDetailsData } = useOrderDetails();

  const [optionType, setOptionType] = useState<PaymentOptionType>(
    getDefaultPaymentOptionType(
      availablePaymentOptions,
      basket.selectedPaymentOption?.optionType,
    ),
  );

  const paymentPlanStartDate = getPaymentPlanLatestStartDate({
    departureDate: departureDate || '',
    paymentOption: optionType,
  });

  const remainingPaymentAfterDeposit = useMemo(() => {
    return basket.tripTotal - tourDeposit;
  }, [basket.tripTotal, tourDeposit]);

  const radioAccordionOptions = availablePaymentOptions
    .map((option) => {
      switch (option.type) {
        case PaymentOptionType.FullBalance: {
          return {
            value: PaymentOptionType.FullBalance,
            label: 'Pay full balance now',
            endAdornment: (
              <Typography variant="Body M">
                {formatCurrency(basket.tripTotal, basket.currency as CustomerCurrency)}{' '}
                today
              </Typography>
            ),
            content: null,
          };
        }
        case PaymentOptionType.BookNowPayLater: {
          const currencyLabel = formatCurrency(
            remainingPaymentAfterDeposit,
            basket.currency as CustomerCurrency,
          );

          const depositDate = departureDate
            ? subDays(new Date(departureDate), 90).toISOString()
            : null;

          const dateLabel = depositDate
            ? ` ${formatDateToOrdinalMonthYear(depositDate)}`
            : '';

          return {
            value: PaymentOptionType.BookNowPayLater,
            label: 'Deposit only',
            endAdornment: (
              <Typography variant="Body M">
                {formatCurrency(tourDeposit, basket.currency as CustomerCurrency)} today
              </Typography>
            ),
            content: (
              <Stack gap={1}>
                <Typography variant="Body M">
                  The remaining {currencyLabel} will be taken automatically on
                  <b>{dateLabel}</b>. We will remind you before this happens.
                </Typography>
              </Stack>
            ),
          };
        }
        case PaymentOptionType.ThreeMonthsPlan:
        case PaymentOptionType.SixMonthsPlan: {
          if (!departureDate) {
            return null;
          }

          return createPaymentPlanOptionContentV2({
            paymentPlanType: option.type,
            paymentPlanStartDate: paymentPlanStartDate,
            basket,
            departureDate,
          });
        }
        default: {
          return null;
        }
      }
    })
    .filter((option) => option !== null) as RadioAccordionGroupOption[];

  const onSave = async () => {
    setSaving(true);
    try {
      await updateBasketPaymentPlan({
        variables: {
          input: {
            id: basket.id,
            selectedPaymentOption: {
              optionType: optionType as PaymentOptionType,
              depositAmount: tourDeposit,
              paymentPlan:
                optionType === PaymentOptionType.ThreeMonthsPlan ||
                optionType === PaymentOptionType.SixMonthsPlan
                  ? {
                      startDate: new Date(paymentPlanStartDate).toISOString(),
                    }
                  : null,
            },
          },
        },
      });
      onComplete(CheckoutSectionName.PaymentOptionsSection);
    } catch (error) {
      setError('Unexpected error occurred, please try again later');
    } finally {
      setSaving(false);
    }
  };

  return (
    <CheckoutExpandableSection
      title="Payment options"
      open={activeSection === CheckoutSectionName.PaymentOptionsSection}
      completed={completed}
      editable={editable}
      onEdit={() => {
        updateQueryParams(
          {
            preserveExisting: true,
            toAdd: { section: CheckoutSectionName.PaymentOptionsSection },
          },
          { replace: true },
        );
      }}
    >
      <Stack>
        {orderDetailsData?.orderDetails.promoCodesEnabled && (
          <Stack my={3}>
            <Typography variant="Body S">Add promotional code</Typography>
            <PromotionalCode
              basketId={basket.id}
              currencyCode={currencyCode}
              departureCode={departureCode}
              appliedPromocodes={basket.appliedPromocodes}
            />
          </Stack>
        )}
        {!isOnlyFullBalanceOptionAvailable(availablePaymentOptions) && (
          <Box mb={5}>
            {/* todo: only render this when orderDetailsdata.promoCodesEnabled is true */}
            <RadioAccordionGroup
              options={radioAccordionOptions}
              defaultValue={optionType}
              onSelectedChange={(value) => {
                setOptionType(value as PaymentOptionType);
              }}
              testid="payment-options"
            />
          </Box>
        )}
        {error && <GenericError error={error} />}
        <LoadingButton
          loading={saving}
          sx={{ alignSelf: 'flex-start', backgroundColor: theme.palette.principal.black }}
          variant="contained"
          onClick={() => {
            void onSave();
          }}
        >
          Save & continue
        </LoadingButton>
      </Stack>
    </CheckoutExpandableSection>
  );
};

export const PaymentOptionsSectionTitle = ({ completed }: { completed: boolean }) => {
  return (
    <CheckoutExpandableSection
      bottomDivider
      title="Payment options"
      completed={completed}
    />
  );
};
