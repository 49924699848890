import { FC } from 'react';
import { Basket, CustomerCurrency } from '@flashpack/graphql';
import { usePaymentBreakdown } from '@src/shared/hooks';
import { PaymentBreakdownContent } from './PaymentBreakdownContent';

export const PaymentBreakdownV3: FC<{ basket: Basket }> = ({ basket }) => {
  const { currency: basketCurrency } = basket;
  const currency = basketCurrency.toLowerCase() as CustomerCurrency;
  const breakdown = usePaymentBreakdown(basket);

  if (!breakdown) {
    return null;
  }

  return <PaymentBreakdownContent currency={currency} {...breakdown} />;
};
