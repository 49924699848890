import {
  Basket,
  CurrentMinimalCustomerDetailsDocument,
  CustomerCurrency,
} from '@flashpack/graphql';
import { useRouting } from '@src/shared/hooks';
import {
  FinishSection,
  FinishSectionTitle,
} from '@src/v3/checkout-sections/finish/FinishSection';
import { HoldYourSpotSection } from '@src/v3/checkout-sections/hold-your-spot/HoldYourSpotSection';
import { PaySection, PaySectionTitle } from '@src/v3/checkout-sections/PaySection';
import {
  PaymentOptionsSection,
  PaymentOptionsSectionTitle,
} from '@src/v3/checkout-sections/PaymentOptionsSection';
import { PersonaliseBookingSection } from '@src/v3/checkout-sections/PersonaliseBookingSection';
import { Stack } from 'design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BillingDetailsSection,
  BillingDetailsSectionTitle,
} from '@src/v3/checkout-sections/billing/BillingDetailsSection';
import { useQuery } from '@apollo/client';
import { useAuthentication } from '@src/auth/useAuthentication';
import { triggerConvertConversion } from '@src/shared/utils/trackingUtils';

type CheckoutSectionsProps = {
  basket: Basket | null;
  basketLoading: boolean;
  isInAgeSweetspot?: boolean;
  isOver45Trip?: boolean;
  payCompleted: boolean;
  setPayCompleted: (value: boolean) => void;
  showReservationTimer: boolean;
};

export const CheckoutSections = ({
  basket,
  basketLoading,
  isInAgeSweetspot = false,
  isOver45Trip = false,
  payCompleted,
  setPayCompleted,
  showReservationTimer,
}: CheckoutSectionsProps) => {
  const { queryParams } = useRouting<{
    section: CheckoutSectionName;
    currencyCode: string;
  }>();

  const { currencyCode, section } = queryParams;
  const customerCurrency = currencyCode.toLowerCase() as CustomerCurrency;
  const { authenticated } = useAuthentication();

  const { data: customerDetailsData, loading: customerDetailsLoading } = useQuery(
    CurrentMinimalCustomerDetailsDocument,
    {
      skip: !authenticated,
    },
  );
  const currentUser = customerDetailsData?.currentCustomerDetails;

  const { updateQueryParams } = useRouting<{ section: CheckoutSectionName }>();

  const [personaliseBookingCompleted, setPersonaliseBookingCompleted] = useState(() => {
    if (
      queryParams.section === CheckoutSectionName.PaymentOptionsSection ||
      queryParams.section === CheckoutSectionName.BillingDetailsSection ||
      queryParams.section === CheckoutSectionName.PaySection
    ) {
      return true;
    }
    return false;
  });

  const [paymentOptionsCompleted, setPaymentOptionsCompleted] = useState(() => {
    if (
      queryParams.section === CheckoutSectionName.BillingDetailsSection ||
      queryParams.section === CheckoutSectionName.PaySection
    ) {
      return true;
    }
    return false;
  });

  const [billingDetailsCompleted, setBillingDetailsCompleted] = useState(() => {
    if (queryParams.section === CheckoutSectionName.PaySection) {
      return true;
    }
    return false;
  });

  const paySucceeded = payCompleted || basket?.payment?.status === 'succeeded';

  useEffect(() => {
    if (paySucceeded) {
      setPersonaliseBookingCompleted(true);
      setPaymentOptionsCompleted(true);
      setBillingDetailsCompleted(true);
      setPayCompleted(true);

      updateQueryParams({
        toAdd: { section: CheckoutSectionName.Finish },
        preserveExisting: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paySucceeded]);

  useEffect(() => {
    if (!section && currentUser) {
      updateQueryParams({
        toAdd: { section: CheckoutSectionName.PersonaliseBookingSection },
        preserveExisting: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, section]);

  const nextIncompleteSection = useMemo(() => {
    if (
      !personaliseBookingCompleted &&
      queryParams.section !== CheckoutSectionName.PersonaliseBookingSection
    ) {
      return CheckoutSectionName.PersonaliseBookingSection;
    }

    if (
      !paymentOptionsCompleted &&
      queryParams.section !== CheckoutSectionName.PaymentOptionsSection
    ) {
      return CheckoutSectionName.PaymentOptionsSection;
    }

    if (
      !billingDetailsCompleted &&
      queryParams.section !== CheckoutSectionName.BillingDetailsSection
    ) {
      return CheckoutSectionName.BillingDetailsSection;
    }

    if (!payCompleted && queryParams.section !== CheckoutSectionName.PaySection) {
      return CheckoutSectionName.PaySection;
    }

    return CheckoutSectionName.Finish;
  }, [
    personaliseBookingCompleted,
    paymentOptionsCompleted,
    billingDetailsCompleted,
    payCompleted,
    queryParams.section,
  ]);

  const onCompletePersonaliseBookingSection = useCallback(() => {
    updateQueryParams({
      toAdd: {
        section: nextIncompleteSection,
      },
      preserveExisting: true,
    });
    setPersonaliseBookingCompleted(true);
  }, [updateQueryParams, nextIncompleteSection]);

  const onCompletePaymentOptionsSection = useCallback(() => {
    updateQueryParams({
      toAdd: {
        section: nextIncompleteSection,
      },
      preserveExisting: true,
    });
    setPaymentOptionsCompleted(true);
  }, [updateQueryParams, nextIncompleteSection]);

  const onCompleteBillingDetailsSection = useCallback(() => {
    updateQueryParams({
      toAdd: {
        section: nextIncompleteSection,
      },
      preserveExisting: true,
    });
    setBillingDetailsCompleted(true);
  }, [updateQueryParams, nextIncompleteSection]);

  const onCompletePaySection = useCallback(() => {
    triggerConvertConversion();
    updateQueryParams({
      toAdd: {
        section: nextIncompleteSection,
      },
      preserveExisting: true,
    });
    setPayCompleted(true);
  }, [updateQueryParams, nextIncompleteSection]);

  if (basketLoading || customerDetailsLoading) {
    return null;
  }

  return (
    <Stack direction="column" gap={4} sx={{ mt: 2 }}>
      <HoldYourSpotSection
        basket={basket}
        loading={basketLoading || customerDetailsLoading}
        isInAgeSweetspot={isInAgeSweetspot}
        isOver45Trip={isOver45Trip}
        showReservationTimer={showReservationTimer}
      />
      <PersonaliseBookingSection
        basket={basket}
        completed={personaliseBookingCompleted}
        onComplete={onCompletePersonaliseBookingSection}
        activeSection={queryParams.section}
        editable={!paySucceeded}
        showReservationTimer={showReservationTimer}
      />
      {basket ? (
        <PaymentOptionsSection
          completed={paymentOptionsCompleted}
          onComplete={onCompletePaymentOptionsSection}
          activeSection={queryParams.section}
          basket={basket}
          editable={!paySucceeded}
        />
      ) : (
        <PaymentOptionsSectionTitle completed={paymentOptionsCompleted} />
      )}
      {basket ? (
        <BillingDetailsSection
          completed={billingDetailsCompleted}
          onComplete={onCompleteBillingDetailsSection}
          activeSection={queryParams.section}
          basket={basket}
          editable={!paySucceeded}
          customerDetails={customerDetailsData?.currentCustomerDetails}
          customerDetailsLoading={customerDetailsLoading}
        />
      ) : (
        <BillingDetailsSectionTitle completed={billingDetailsCompleted} />
      )}
      {basket ? (
        <PaySection
          completed={payCompleted}
          onComplete={onCompletePaySection}
          activeSection={queryParams.section}
          basket={basket}
          customerCurrency={customerCurrency}
          editable={!paySucceeded}
        />
      ) : (
        <PaySectionTitle />
      )}
      {basket ? (
        <FinishSection
          activeSection={queryParams.section}
          completed={false}
          basket={basket}
        />
      ) : (
        <FinishSectionTitle />
      )}
    </Stack>
  );
};

export enum CheckoutSectionName {
  CreateAccountSection = 'create-account',
  PersonaliseBookingSection = 'personalise-booking',
  PaymentOptionsSection = 'payment-options',
  BillingDetailsSection = 'billing-details',
  PaySection = 'pay',
  Finish = 'finish',
}
